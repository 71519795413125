<div class="banner-container">
  <div class="banners-swiper-container swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide plan">
        <div class="banner-section">
          <picture class="banner-image-container">
            <source width="639" height="520" srcset='assets/img/banners/home-page-banner-mobile.webp'
              media="(max-width: 639.9px)" type="image/webp">
            <source width="639" height="520" srcset='assets/img/banners/home-page-banner-mobile.png'
              media="(max-width: 639.9px)" type="image/png">
            <source width="1024" height="500" srcset='assets/img/banners/home-page-banner-tablet.webp'
              media="(max-width: 1024.9px)" type="image/webp">
            <source width="1024" height="500" srcset='assets/img/banners/home-page-banner-tablet.png'
              media="(max-width: 1024.9px)" type="image/png">
            <source width="2000" height="500" srcset='assets/img/banners/home-page-banner-desktop.webp'
              media="(min-width: 1025px)" type="image/webp">
            <source width="2000" height="500" srcset='assets/img/banners/home-page-banner-desktop.png'
              media="(min-width: 1025px)" type="image/png">
            <img width="2000" height="500" src="assets/img/banners/home-page-banner-desktop.png"
              alt="Home Page Banner Image">
          </picture>
          <div class="banner-content-container">
            <img class="home-page-header" src="assets/img/home-header.svg" width="319" height="219"
              alt="Home Header Image" *ngIf="innerWidth > 639">
            <img class="home-page-header" src="assets/img/home-header-mobile.svg" width="281" height="90"
              alt="Home Header Image" *ngIf="innerWidth < 640">
            <p class="description smaller"><b>Check out our UNLIMITED plans starting at</b></p>
            <h1 class="price">$10/mo!</h1>
            <button class="button secondary" id="shop-now" (click)="goToPlans()" title="Shop Now!" value="Shop Now!"
              aria-label="Shop Now!">Shop Now!</button>
          </div>
          <div class="footer-part page-section">
            <p class="disclaimer footer">*After high-speed data allowance is used, data speeds are slowed to a maximum
              of 128Kbps for the
              rest of the term.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-container-override banner-slider-override">
      <div class="container">
        <div class="row">
          <ul class="slick-dots banners slider-pagination"></ul>
        </div>
        <div class="swiper-button-next">
        </div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</div>