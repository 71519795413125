<header class="header" [class.menuShown]="!!showResponsiveMenu" id="header" height="64">
  <div class="page-section header-container">
    <div class="logo-section">
      <img src="/assets/icon/humburger.svg" alt="hamburger icon" class="hamburger responsive"
        (click)="showResponsiveMenu=!showResponsiveMenu;toggleShowing()" />
      <div class="responsive-wrapper responsive" [class.menuShown]="!!showResponsiveMenu">
        <div class="responsive-menu-header">
          <div class="logo"><a [routerLink]="[ROUTE_URLS.HOME]"
              (click)="showResponsiveMenu = false;toggleShowing()"><img alt="goodMobile logo" height="45"
                src="/assets/img/logo.svg" /></a></div>
          <button class="close" (click)="showResponsiveMenu = false;toggleShowing()"><i class="icon-close"></i></button>
        </div>
        <ul class="responsive-menu">
          <li class="responsive-item sub-menu-list" *ngIf="!!isLoggedIn">
            <div class="label-section" (click)="showAccountMenu = !showAccountMenu">
              <div class="name">
                <p><img class="item-icon" src="assets/icon/account.svg" alt="Account" /> Account
                </p>
              </div>
              <div class="toggle-side"> <i class="arrow primary"
                  [ngClass]="{'down': !showAccountMenu, 'up': !!showAccountMenu}"></i></div>
            </div>
            <div class="menu-wrapper" [class.menuShown]="!!showAccountMenu">
              <ul class="sub-menu">
                <li class="item"><a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                    id="account-summary-header" data-cy="account-summary-header-responsive">account summary</a></li>
                <li *ngIf="!!displayAcpSection" class="item"><a
                    [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ACP_APPLICATION]"
                    (click)="onLinkClick($event, 'acp')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" id="acp-application-header">ACP summary</a></li>
                <li class="item"><a
                    [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    (click)="onLinkClick($event, 'pendingPlans')" id="purchased-plans-header"
                    data-cy="purchased-plans-header-responsive">purchased plans</a></li>
                <li class="item" [class.disabled]="!!isPortIn"><a (click)="onLinkClick($event, 'refill')"
                    [class.disabled]="!!isPortIn" id="refill-account-header" data-cy="refill-account-header-responsive"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">pay &
                    refill your account</a></li>
                <li class="item" [class.disabled]="!!isExpiredAccount || !!isPortIn"><a
                    (click)="onLinkClick($event, 'addon')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" [class.disabled]="!!isExpiredAccount || !!isPortIn"
                    id="plan-addOns-header" data-cy="plan-addOns-header-responsive">plan add-ons</a></li>
                <li class="item"><a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]"
                    (click)="onLinkClick($event, 'orders')" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" id="your-orders-header">Your orders</a></li>
                <li class="item" [class.disabled]="!!isPortIn"><a (click)="onLinkClick($event, 'payment')"
                    [class.disabled]="!!isPortIn" id="payment-history-header">payment history</a></li>
                <li class="item" [class.disabled]="!!isPortIn"><a (click)="onLinkClick($event, 'usage')"
                    [class.disabled]="!!isPortIn" id="usage-history-header"
                    data-cy="usage-history-header-responsive">usage
                    history</a></li>
                <li class="item"><a [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                    id="manage-device-header" data-cy="manage-device-header-responsive">manage device & sim</a></li>
                <li class="item"><a [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                    id="profile-setting-header">profile settings</a></li>
                <li class="item" (click)="logout()"><a>logout</a></li>
              </ul>
            </div>
          </li>
          <li class="responsive-item" *ngIf="!isLoggedIn">
            <img class="item-icon" src="assets/icon/account.svg" alt="sign in" />
            <a [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" (click)="showResponsiveMenu = false;toggleShowing()">Sign In
            </a>
          </li>
          <li class="responsive-item">
            <img class="item-icon" src="assets/icon/home-page.svg" alt="home" />
            <a id="homeMenu" [routerLink]="[ROUTE_URLS.HOME]" (click)="onLinkClick($event)">Home</a>
          </li>
          <li class="responsive-item sub-menu-list" id="shopMenu">
            <div class="label-section" (click)="showShopMenu = !showShopMenu">
              <div class="name">
                <p><img class="item-icon" src="assets/icon/cart.svg" alt="shop" /> Shop
                </p>
              </div>
              <div class="toggle-side"> <i class="arrow primary"
                  [ngClass]="{'down': !showShopMenu, 'up': !!showShopMenu}"></i></div>
            </div>
            <div class="menu-wrapper" [class.menuShown]="!!showShopMenu"
              *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
              <ul class="sub-menu">
                <li class="item"><a data-cy="plansLinkResponsive"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    (click)="onLinkClick($event)">Plans</a></li>
                <!-- <li class="item"><a [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.ACP_DEVICES]"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                    data-cy="devicesLinkResponsive">Devices</a></li> -->
              </ul>
            </div>
            <div class="menu-wrapper active-plans" [class.menuShown]="!!showShopMenu"
              *ngIf="!!isLoggedIn && !!userHasActivePlans">
              <div class="user-account-part">
                <div class="title-flex">
                  <p class="title">Your Account</p>
                  <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                    (ngModelChange)="userPlanSelected($event)" [compareWith]="planCompare">
                    <option *ngFor="let plan of userPlans" [ngValue]="plan">
                      {{getSelectorTitle(plan)}}
                    </option>
                  </select>
                </div>
                <p class="sub-title">
                  <button class="button thirdly" data-cy="changePlanResponsive" (click)="changePlan()"
                    [class.disabled]="!!isPortIn">Change
                    Plan</button>
                </p>
                <p class="sub-title last">
                  <button class="button thirdly" data-cy="orderAddOnsPlanResponsive" (click)="goToAddOns()"
                    [class.disabled]="!!isPortIn || !!isExpiredAccount">Order
                    Add-ons</button>
                </p>
              </div>
              <div class="new-account-part">
                <p class="title">New Account</p>
                <p class="sub-title last">
                  <button class="button thirdly" data-cy="plansLinkResponsive" (click)="onLinkClick($event)"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Purchase
                    new Plan</button>
                </p>
                <!-- <p class="sub-title last">
                  <button class="button thirdly" data-cy="devicesLinkResponsive" (click)="onLinkClick($event)"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.ACP_DEVICES]">ACP Devices</button>
                </p> -->
              </div>
            </div>
          </li>
          <li class="responsive-item">
            <img class="item-icon" src="assets/icon/mobile.svg" alt="bring your phone" />
            <a [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)" id="byod">Bring your phone</a>
          </li>
          <li class="responsive-item">
            <img class="item-icon" src="assets/icon/wifi.svg" alt="coverage" />
            <a [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              (click)="onLinkClick($event)">Coverage</a>
          </li>
          <li class="responsive-item">
            <img class="item-icon" src="assets/icon/toggle.svg" alt="activate" />
            <a id="activate-responsive" data-cy="activateResponsive"
              [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)">Activate</a>
          </li>
        </ul>
      </div>
      <a [routerLink]="[ROUTE_URLS.HOME]" (click)="menuShowing = false;">
        <img src="assets/img/logo.svg" class="logo" alt="GoodMobile" width="134" height="32">
      </a>
    </div>
    <div class="list-section desktop">
      <ul class="menu">
        <li class="menu-item"><a id="home-header" [routerLink]="[ROUTE_URLS.HOME]"
            (click)="onLinkClick($event)">Home</a>
        </li>
        <li class="menu-item" id="shopMenu-header" data-cy="shopMenuDesktop">Shop <img class="shop-arrow"
            src="assets/icon/shop-arrow.svg" alt="Shop Arrow" />


          <div class="shop-menu" *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
            <img class="shop-menu-arrow" src="assets/icon/shop-menu-arrow.svg" alt="Shop Menu Arrow" />
            <p class="item"><a data-cy="plansLinkDesktop"
                [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                (click)="onLinkClick($event)">Plans</a></p>
            <!-- <div class="menu-border"></div>
            <p class="item"><a data-cy="devicesLinkDesktop" [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.ACP_DEVICES]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                (click)="onLinkClick($event)">Devices</a></p> -->
          </div>
          <div class="shop-menu active-plans" *ngIf="!!isLoggedIn && !!userHasActivePlans">
            <div class="user-account-part">
              <div class="mdn-cell">
                <p class="title">Your Account</p>
                <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                  (ngModelChange)="userPlanSelected($event)" [compareWith]="planCompare">
                  <option *ngFor="let plan of userPlans" [ngValue]="plan">
                    {{getSelectorTitle(plan)}}
                  </option>
                </select>
              </div>
              <p class="sub-title">
                <button class="button thirdly" data-cy="changePlanDesktop" (click)="changePlan()"
                  [class.disabled]="!!isPortIn">Change Plan</button>
              </p>
              <p class="sub-title last">
                <button class="button thirdly" data-cy="orderAddOnsPlanDesktop" (click)="goToAddOns()"
                  [class.disabled]="!!isPortIn || !!isExpiredAccount">Order Add-ons</button>
              </p>
            </div>
            <div class="border"></div>
            <div class="new-account-part">
              <p class="title">New Account</p>
              <p class="sub-title">
                <button class="button thirdly" data-cy="plansLinkDesktop" (click)="onLinkClick($event)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Purchase
                  new Plan</button>
              </p>
              <!-- <p class="sub-title last">
                <button class="button thirdly" data-cy="devicesLinkDesktop" (click)="onLinkClick($event)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.ACP_DEVICES]">ACP Devices</button>
              </p> -->
            </div>
          </div>
        </li>
        <li class="menu-item"><a id="check-your-phone-header" data-cy="check-your-phone-header"
            [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            (click)="onLinkClick($event)">Bring
            your phone</a></li>
        <li class="menu-item"><a id="coverage-header" data-cy="coverage-header"
            [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)">Coverage</a></li>
        <li class="menu-item"> <a id="activate" data-cy="activateDesktop"
            [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)">Activate</a></li>
      </ul>
    </div>
    <div class="icons-section">
      <ul class="login-list desktop">
        <li class="login-option" *ngIf="!isLoggedIn">
          <a [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="menuShowing = false;"> Sign In </a>
        </li>
        <li class="login-option" *ngIf="!!isLoggedIn">
          <div class="label-section">
            <img class="account" data-cy="account-menu-header" src="assets/icon/Avatar.svg"><i class="arrow primary"
              (mouseenter)="showAccountMenu=true" (mouseleave)="showAccountMenu=false"
              [ngClass]="{'down': !showAccountMenu, 'up': !!showAccountMenu}"></i>
          </div>
          <div class="wrapper">
            <nav class="dropdown">
              <a class="sub-item" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                id="account-summary-header" data-cy="account-summary-header-desktop">Account summary</a>
              <a *ngIf="!!displayAcpSection" class="sub-item"
                [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ACP_APPLICATION]"
                (click)="onLinkClick($event, 'acp')" id="acp-application-header" data-cy="acp-application-header">ACP
                summary</a>
              <a class="sub-item" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                (click)="onLinkClick($event, 'pendingPlans')" id="purchased-plans-header"
                data-cy="purchased-plans-header-desktop">Purchased plans</a>
              <a class="sub-item" (click)="onLinkClick($event, 'refill')" [class.disabled]="!!isPortIn"
                id="refill-account-header" data-cy="refill-account-header-desktop" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Pay &
                refill your account</a>
              <a class="sub-item" (click)="onLinkClick($event, 'addon')" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" [class.disabled]="!!isExpiredAccount || !!isPortIn"
                id="plan-addOns-header" data-cy="plan-addOns-header-desktop">Plan add-ons</a>
              <a class="sub-item" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]"
                (click)="onLinkClick($event, 'orders')" id="your-orders-header">Your
                orders</a>
              <a class="sub-item" (click)="onLinkClick($event, 'payment')" [class.disabled]="!!isPortIn"
                id="payment-history-header" data-cy="payment-history-header">Payment history</a>
              <a class="sub-item" (click)="onLinkClick($event, 'usage')" [class.disabled]="!!isPortIn"
                id="usage-history-header" data-cy="usage-history-header-desktop">Usage
                history</a>
              <a class="sub-item" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                id="manage-device-header" data-cy="manage-device-header-desktop">Manage device & sim</a>
              <a class="sub-item" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onLinkClick($event)"
                id="profile-setting-header" data-cy="profile-setting-header">Profile settings</a>
              <a class="sub-item" (click)="logout($event)" id="logout-header" data-cy="logout-header">Logout</a>
            </nav>
          </div>
        </li>
      </ul>
      <ul class="icons-list">
        <li class="item">
          <button class="menu-btn" (click)="goToCart()">
            <img width="32" height="32" src="/assets/icon/cart.svg" alt="cart icon"><span class="counter"
              *ngIf="hasCartItem && totalItems != 0">{{totalItems}}</span></button>
        </li>
        <li class="item">
          <button class="menu-btn" (click)="getPriority()">
            <img width="32" height="32" src="/assets/icon/bell.svg" alt="bell icon"><span class="counter"
              *ngIf="hasCartItem && totalItems != 0">{{totalItems}}</span></button>
        </li>
      </ul>
    </div>
  </div>
</header>
<div class="alert-banner" *ngIf="!!mantainenceDescription && !!displayMaintenanceBanner && !outageBanner">
  <img class="alert-icon" src="assets/icon/alert.svg" alt="alert">
  <div class="message" [innerHTML]="mantainenceDescription"></div>
</div>
<div class="outage-banner" *ngIf="!!outageBanner">
  <div class="flex">
    <img src="assets/icon/error-icon.svg" width="32" height="32" alt="Alert Icon">
    <div class="banner-content">
      <h5 class="title">Network is currently experiencing a major service outage, impacting large portions of the
        country.</h5>
      <p class="smaller">Engineers are working to restore service. At this time, there is no ETA for restoral, but we
        expect a quick resolution to this critical service outage.</p>
    </div>
  </div>
</div>
<div class="warning-banner" *ngIf="!!showACPNotice">
  <div class="flex">
    <div class="flex-title">
      <img src="assets/icon/warning-icon.svg" width="32" height="32" alt="warning Icon">
      <div class="banner-content">
        <h5 class="title">Important Announcement about the Affordable Connectivity Program (ACP). <button class="button tertiary" (click)="goToACPApplication()">View More</button></h5>
      </div>
    </div>
    <img src="assets/icon/close-blue.svg" (click)="closeBanner()" class="close-icon" width="24" height="24"
      alt="Warning Icon">
  </div>
</div>
<!-- <div class="acp-alert-banner" *ngIf="!!showACPActionBanner" id="alert">
  <div class="container-flex">
    <h5 class="title">
      <img class="alert-icon" src="assets/icon/red-alert.svg" alt="alert">
      <span>Action Required!</span>
    </h5>
    <p class="desc smaller">An ACP application has started! Resume your application now.</p>
    <button class="button primary" aria-label="Continue Application" (click)="goToACPApplication()">Continue
      Application</button>
  </div>
</div> -->
<!-- <div class="acp-alert-banner warning" *ngIf="!!showResumeFilingContent || !!showActivatePlanContent" id="alert">
  <div class="container-flex">
    <h5 class="title">
      <img class="alert-icon" src="assets/icon/warning-icon.svg" alt="Warning Icon">
      <span>Attention Required!</span>
    </h5>
    <ng-container *ngIf="!!showResumeFilingContent">
      <p class="desc smaller">To start using your free service, please make sure to finish your ACP approval process
        within
        <b>30 days</b> of your application date.
      </p>
      <button class="button primary" aria-label="Resume Filing" (click)="goToAcp()">Resume Filing</button>
    </ng-container>
    <ng-container *ngIf="!!showActivatePlanContent">
      <p class="desc smaller">Please make sure to <b>activate</b> your ACP plan to <b>avoid service loss.</b></p>
      <button class="button primary" aria-label="Activate Now" (click)="activatePlan()">Activate Now!</button>
    </ng-container>
  </div>
</div> -->