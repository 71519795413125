<div class="modal">
<div class="container model-container {{context.customClass}}">
    <div class="modal-header">
        <button class="modal-close modal-toggle"  data-cy="closeIcon" (click)="closeDialog(false)"><i class="icon-close"></i></button>
        <h2 class="modal-heading" >{{context.title}}</h2>
    </div>
    <div class="modal-body">
        <div class="modal-content">
            <div class="form-section">
                <app-address-lookup [showAlias]="true"  [displayedAddress]="address" (isValid)="setValidAddress($event)" (addressChange)="addressChanged($event)"></app-address-lookup>
            </div>
            <div class="modal-actions">
                <button  class="button primary" data-cy="save" (click)="saveAddress()" [disabled]="!isValidAddress">
                  Save
                </button>
                <button class="button transparent" (click)="closeDialog(false)"> Cancel</button>
              </div>
        </div>
    </div>
</div>
</div>