<div class="modal">
  <div class="container model-container email-verification">
    <div class="modal-header">
      <h2 class="header">Email verification needed!</h2>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <p>
          We noticed you signed up with us, but have not verified your email yet.
        </p>
        <div class="modal-actions">
          <button class="button primary" (click)="sendCode()">Send Verification Code</button>
        </div>
      </div>
    </div>
  </div>
</div>
