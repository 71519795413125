<div class="modal">
  <div class="container model-container recaptcha-verification">
    <div class="modal-header">
      <h4 class="modal-heading">Before we continue ..</h4>
      <p class="smaller modal-heading-note">Please check the box below to verify you are not a robot!</p>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <app-re-captcha (resolved)="resolvedCaptcha($event)" [siteKey]="SITE_ID" #reCaptcha
          class="recaptcha"></app-re-captcha>
        <p class="validation-message" *ngIf="!!verifyClicked && !captchaResponse">Please check the box to proceed</p>
        <button class="button primary" (click)="verifyRecaptcha()">Verify</button>
      </div>
    </div>
  </div>
</div>