<div class="modal">
<div class="container model-container {{settings.customClass || ''}}">
    <div class="modal-header">
      <h3 class="modal-heading">{{settings.title || ''}}</h3>
      <button id="close-popup-btn"  data-cy="closeIcon" class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="row">
          <div *ngIf="!!settings?.customHTML || !!context?.message">
            <div *ngIf="!settings.enableHTML" class="msg">
              {{context.message}}
            </div>
            <div *ngIf="settings.enableHTML" [innerHTML]="context.message" class="msg"></div>
            <div *ngIf="!!settings.customHTML" [innerHTML]="settings.customHTML"></div>
          </div>
        </div>
        <div class="modal-actions">
          <button id="confirm-btn" data-cy="confirm-btn" class="button primary" (click)="OK()">{{settings.okText || 'OK'}}</button>
          <button id="cancel-btn" data-cy="cancel-btn" class="button transparent" (click)="cancel()">{{settings.cancelText || 'Cancel'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

  