<div class="modal">
<div class="container model-container {{context.customClass}}">
  <div class="modal-header">
    <h2 class="modal-heading" *ngIf="!!context.title">{{context.title}}</h2>
    <button *ngIf="context.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog()"><i
      class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <div class="modal-content">
      <div class="row">
        <div id="PROTRACTOR_MODAL_MSG" *ngIf="!context.customHTML" class="msg">
          {{context.message}}
        </div>
        <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
      </div>
      <div class="modal-actions">
        <button class="button primary" (click)="OK()" id="action-button" data-cy="okActionButton" *ngIf="!context.btnUrl">{{context.btnText || 'OK'}}</button>
        <button class="button transparent" *ngIf="!!context.cancelBtn" (click)="cancel()" id="action-button" data-cy="cancelActionButton">{{context.cancelText ||
          'Cancel'}}</button>
          <a [href]="context.btnUrl" target="_blank" *ngIf="!!context.btnUrl">
            <button class="button primary" (click)="OK()" id="modal-button">{{context.btnText || 'OK'}}</button>
          </a>   
      </div>
      <div class="note" *ngIf="!!context.noteText">
        <p class="details">{{context.noteText}}</p>
      </div>
    </div>
  </div>
</div>
</div>