<div class="bring-phone-page">
    <div class="top-banner">
        <h1 class="banner-head">Love your device?</h1>
        <h4 class="banner-subtitle">Bring it to Good Mobile</h4>
        <button class="button primary specific" data-cy="getStartedBtn" (click)="goToCompatibility()">Get Started</button>
    </div>
    <div class="great-things-section">
        <p class="label">START WITH GOOD MOBILE</p>
        <h2 class="sec-head">Bring your own device to Good Mobile</h2>
        <div class="things-details">
            <div class="item-details">
                <img src="/assets/icon/device-check.svg" alt="device-icon">
                <h5 class="title">Keep the device you love</h5>
                <p class="smaller desc">Let’s find out if your device is compatible with our network.</p>
                <a class="link" (click)="goToCompatibility()">Check Device<img src="/assets/icon/next-blue-arrow.svg"
                        alt="next-arrow"></a>
            </div>
            <div class="item-details">
                <img src="/assets/icon/shop-plans.svg" alt="coverage-icon">
                <h5 class="title">Choose the Right Plan for you</h5>
                <p class="smaller desc spcific">Get a FREE SIM kit when you sign up to a Good Mobile plan online.</p>
                <a class="link" (click)="goToPlans()">Shop Plans<img src="/assets/icon/next-blue-arrow.svg"
                        alt="next-arrow"></a>
            </div>

            <div class="item-details">
                <img src="/assets/icon/activate-req.svg" alt="phone-num-icon">
                <h5 class="title">Activate</h5>
                <p class="smaller desc spcific no-bottom">Keep your existing phone number or request a new one.</p>
            </div>
        </div>
    </div>
    <div class="page-section section-container reverse">
        <div class="details-section">
            <p class="label">PREMIUM WIRELESS</p>
            <h2 class="header">Our best value plans</h2>
            <h4 class="sub-header">You need a plan that suits your lifestyle !</h4>
            <h5 class="details">Customers who join Good Mobile enjoy premium data service provided by one of the
                country’s fastest networks. With our choice of monthly plans, you can get exactly what
                you need from your mobile!</h5>
            <div class="action">
                <button class="button primary" (click)="goToPlans()">Learn More</button>
            </div>
        </div>
        <div class="image-section center">
            <picture>
                <source height="500" srcset='assets/img/love-bring-phone.png' media="(min-width: 1800px)"
                    type="image/png">
                <source height="300" srcset='assets/img/love-phone-normal.png' media="(min-width: 640px)"
                    type="image/png">
                <source height="300" srcset='assets/img/love-bring-phone-mobile.png' media="(max-width: 640px)"
                    type="image/png">
                <img height="300" src="assets/img/love-phone-normal.png" alt="love bring phone">
            </picture>
        </div>
    </div>
    <div class="compatibility-section">
        <p class="label">CHECK COMPATIBILITY</p>
        <h2 class="sec-head bottom">Check your phone’s compatibility with the
            Good Mobile network</h2>
        <h5 class="desc">Most GSM phones work on our network. Let’s confirm if yours is compatible.</h5>
        <button class="button primary specific" (click)="goToCompatibility()">Check your Phone</button>
    </div>
    <div class="faqs-section page-section">
        <p class="label">FAQ QUESTIONS</p>
        <h2 class="sec-head bottom">We are here to help you!</h2>
        <h5 class="subtext">Browse through the most frequently asked questions.</h5>
        <div class="plans-questions">
            <div *ngFor="let qst of bringYourPhoneQsts" class="questions-container">
                <a class="copy-icon-link"
                    (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId, true)"><img
                        src="/assets/icon/copy-icon.svg" class="copy-icon" alt="copy icon"><span
                        class="tool-tip-text">Copy question url</span> <span
                        *ngIf="!!isCopied && qst?.fields?.questionId == questionIdParam"
                        class="copied">Copied!</span></a>
                <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                    [isActive]="qst?.fields?.questionId == questionIdParam"
                    (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                    <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                </app-question-answer>
            </div>
        </div>
    </div>
</div>