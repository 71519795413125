<div class="modal">
    <div class="container model-container checkout-options" (keypress.esc)="onKeydown($event)">
      <div class="modal-header">
        <h3>How would you like to proceed with your checkout?</h3>
        <button class="modal-close modal-toggle" id="close-icon" (click)="closeDialog()"><i
            class="icon-close"></i></button>
      </div>
      <div class="modal-body">
        <div class="modal-content">
            <div class="options">
                <label class="radio-label" for="customer">
                    <input type="radio" value="customer" [(ngModel)]="option" id="customer"  #optionRef="ngModel"  (ngModelChange)="validateOption()">Self Checkout
                </label>
                <div class="agent-section">
                    <label class="radio-label agent" for="agent" >
                        <input type="radio" value="agent" [(ngModel)]="option"  id="agent" #optionRef="ngModel" (ngModelChange)="validateOption()">
                        <div>
                            <span>Agent Assisted Checkout</span>
                        </div>
                    </label>
                   
                </div>
                <label class="validation-message" *ngIf="optionRef?.touched && !option" id="select-an-option-message">Please
                    select one of the options</label>
            </div>
            
        </div>
        <ng-container *ngIf="!!option && option === 'agent'">
            <div class="agent-form" id="agent-form">
                <form [formGroup]="agentForm">
                    <label class="outlined-label-input">
                        <input id="code" data-cy="agentCode" type="text" formControlName="code" placeholder=" " (input)="invalidCode=false">
                        <span>Enter Agent Code</span>
                    </label>
                    <label class="validation-message caption"
                    *ngIf="agentForm.controls.code.touched && agentForm.controls.code.hasError('required')"
                    id="required-agent-msg" data-cy="requiredAgentMsg">Agent Code is Required </label>
                    <label class="validation-message caption"
                    *ngIf="!!agentForm.controls.code.value && agentForm.controls.code.hasError('pattern')"
                    id="invalid-agent-msg" data-cy="invalidAgentMsg">Agent Code is invalid</label>
                    <label class="validation-message caption"
                    *ngIf="!!invalidCode"
                    id="error-agent-msg" data-cy="invalidAgentMsg">Agent Code is incorrect. Please Try Again</label>
                    <div class="no-id">
                        <p class="smallest">Don’t have an Agent ID?
                        </p>
                        <button class="button tertiary" (click)="closeDialog({flow:'customer'})" id="click-here-btn">Click here</button>
                    </div>
                </form>
            </div>
        </ng-container>
        <div class="action">
            <button class="button primary" id="submit-btn" (click)="submitOption()">Submit</button>
        </div>
      </div>
    </div>
    </div>