import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ALPHANUMERIC_PATTERN } from 'src/app/app.config';
import { ContentfulService } from 'src/services/contentful.service';

@Component({
  selector: 'app-checkout-options',
  templateUrl: './checkout-options.component.html',
  styleUrls: ['./checkout-options.component.scss']
})
export class CheckoutOptionsComponent implements OnInit {
  @ViewChild('optionRef') optionRef;
  public agentForm: FormGroup;
  public option: string;
  public showValidation = false;
  public invalidCode = false;
  public agentCodes = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
  public dialog: MatDialogRef<any>, public contentfulService: ContentfulService) {
    this.agentForm = formBuilder.group({
      code: ['', Validators.compose([Validators.required, Validators.pattern(ALPHANUMERIC_PATTERN)])]
  });
   }

  ngOnInit(): void {
  }

  onKeydown(event) {
    event.stopImmediatePropagation();
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public closeDialog(result?): void {
    this.beforeDismiss();
    this.dialog.close(result);
  }

  public validateOption(): void {
    this.showValidation = false
    if (!!this.option) {
      if (this.option === 'agent') {
        this.contentfulService.getContent('agentCodes').subscribe(res => {
          if (!!res && res?.length > 0) {
              this.agentCodes = res;
          }
        });
      }
    }
  }
  public submitOption(): void {
    this.optionRef.control.markAllAsTouched();
    if (!!this.option) {
      if (this.option === 'agent') {
        this.validateAgentCode();
      } else {
        this.closeDialog({flow: 'customer'})
      }
    } 
  }

  public validateAgentCode(): void {
    this.agentForm.markAllAsTouched();
    if (!!this.agentForm.valid) {
        if (this.agentCodes.some(code => code?.fields?.code === this.agentForm?.controls?.code?.value)) {
            this.invalidCode = false;
            this.closeDialog({code: this.agentForm?.controls?.code?.value, flow: 'agent'})
        } else {
          this.invalidCode = true;
        }
    }
}
}
