<div class="page-section featured-container">
  <div class="description-section">
    <p class="label">OUR FEATURES</p>
    <h2 class="header">Cell phone plan at a great price</h2>
    <h5 class="sub-header">Best selling wireless plans</h5>
  </div>
  <div class="plans-cards-section" *ngIf="innerWidth >= 1025">
    <div class="view-plans" data-cy="viewAllPlans" (click)="viewAllPlans()">
      <h6>View All Plans</h6>
      <img src="assets/icon/circle-right-arrow.svg" alt="Icon">
    </div>
    <div class="cards-section">
      <div class="featured-card-container" *ngFor="let plan of featuredPlans">
        <div class="featured-plan-card">
          <div class="card-header">
            <p class="smallest">{{plan?.category}}</p>
          </div>
          <div class="card-body">
            <img *ngIf="!!plan?.ebb" class="ribbon" src="assets/img/new-ribbon.svg" alt="ribbon">
            <div class="price-section">
              <p class="price"><span class="dollar" *ngIf="plan?.price > 0">$</span>{{plan?.price > 0 ? plan?.price :
                'FREE'}}<span class="month" *ngIf="plan?.price > 0">/mo</span></p>
            </div>
            <div class="data-section">
              <p class="data">{{!!plan?.specialPromotion && plan?.specialPromotion?.promotionData > 0 ?
                (plan?.specialPromotion?.promotionData + plan?.data) / 1024 : (plan?.data / 1024)}}GB</p>
              <p class="lte">premium 5G Data</p>
              <p class="caption">With Unlimited Talk &Text</p>
            </div>
            <div class="action">
              <button class="button primary small" (click)="viewAllPlans()" title="Select-plan" id="select-{{plan?.id}}"
                aria-label="select plan"> Select Plan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="plans-cards-section" *ngIf="innerWidth < 1025">
    <div class="plan-gigs-section">
      <div class="plan-gigs">
        <ng-container *ngFor="let item of planPrices">
          <a (click)="priceSelected(item)" class="gig-item" [class.active-gig]="item === selectedPrice"><span
              class="dollar" *ngIf="item !== 0">$</span>{{item === 0 ? 'FREE' : item}}
            <img src="/assets/icon/dark-tail-arrow.svg" alt="tail" class="tail-arrow" *ngIf="item === selectedPrice">
          </a>
        </ng-container>
      </div>
    </div>
    <div class="cards-section">
      <ng-container *ngFor="let plan of featuredPlans">
        <div class="featured-card-container" *ngIf="selectedPrice === (plan?.price)">
          <div class="featured-plan-card">
            <div class="card-header">
              <p class="smallest">{{plan?.category}}</p>
            </div>
            <div class="card-body">
              <div class="price-section">
                <p class="price"><span class="dollar" *ngIf="plan?.price > 0">$</span>{{plan?.price > 0 ? plan?.price :
                  'FREE'}}<span class="month" *ngIf="plan?.price > 0">/mo</span></p>
              </div>
              <div class="data-section">
                <p class="data">{{!!plan?.specialPromotion && plan?.specialPromotion?.promotionData > 0 ?
                  (plan?.specialPromotion?.promotionData + plan?.data) / 1024 : (plan?.data / 1024)}}GB</p>
                <p class="lte">premium 5G Data</p>

                <p class="caption">With Unlimited Talk &Text</p>
                <p class="caption" *ngIf="!!plan.ebb">The Affordable Connectivity Program {{!istemporarily ? 'has': 'will'}} temporarily <b>{{!istemporarily ? 'stopped': 'stop'}} accepting new applications from February 7, 2024</b>.</p>
              </div>
              <div class="action">
                <button class="button primary small" (click)="viewAllPlans(plan.price)" title="Select-plan"
                  id="select-{{plan?.id}}" aria-label="select plan" *ngIf="!plan?.ebb"> Select Plan</button>
                <button class="button primary small"  title="Learn More" [routerLink]="['/'+ ROUTE_URLS.ACP_WINDDOWN]"
                  id="Learn-more" aria-label="Learn More" *ngIf="!!plan?.ebb">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>