  
<div class="modal">
    <div class="container model-container google-login">
        <div class="modal-header">
          <h4 class="modal-heading">Please login with your Google account to continue </h4>
        </div>
        <div class="modal-body">
          <div class="modal-content">
          <asl-google-signin-button type='standard' size='large' #googleSignInButton>
           </asl-google-signin-button>
          </div>
        </div>
      </div>
      </div>
      