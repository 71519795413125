import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PLANS_SHOP_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS } from 'src/app/app.routes.names';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})

export class BannersComponent implements OnInit, AfterViewInit {
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public ROUTE_URLS = ROUTE_URLS;
  public innerWidth: any;
  public bannerSwiperConfig: SwiperOptions = {
    centeredSlides: true,
    speed: 1000,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.slider-pagination',
      renderBullet: (index, className) => `<li style="z-index: 10" class="slick-active ${className}"><button ></button></li>`,
      clickable: true
    },
    observeParents: true,
    observer: true,
    zoom: {
      toggle: false
    },
    setWrapperSize: false,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.changeDetector?.detectChanges();
    let swiper = new Swiper('.banners-swiper-container', {
      // Enable lazy loading
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      hashNavigation: true,
      lazy: true,
      autoplay: {
        delay: 10000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      preventClicks: false,
      preventClicksPropagation: false,
      ...this.bannerSwiperConfig
    });
    this.changeDetector?.detectChanges();
  }

  ngAfterViewInit() {
    this.changeDetector?.detectChanges();
  }

  public goToPlans(): void {
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = document.documentElement.clientWidth;
  }
}
