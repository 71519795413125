<div class="modal">
<div class="container model-container {{context.customClass}}">
  <div class="modal-header">
    <h2 class="modal-heading">{{context.title}}</h2>
    <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <form #userInputForm="ngForm">
      <div class="container modal-content">
            <div class="row">
              <div class="msg">
                {{context.message}}
              </div>
            </div>
            <div class="row">
              <fieldset>
                <label class="label" for="userInputField">{{context.labelText}}</label>
                <input id="userInputField" type="text" name="userInputField" [(ngModel)]="userInputValue" #userInput
                  required autocomplete="user-input" [minLength]="checkLength()" [maxlength]="checkLength()" [disabled]="disabledIccid">
                <div class="primary-col"><a (click)="showIccidModal()">What is an ICCID number?</a></div>
              </fieldset>
            </div>
        <div class="modal-actions">
            <button class="button {{context.okBtnClass}}" (click)="submitUserInput()"
              [class.disabled]="userInputValue?.length < validLength"
              [disabled]="userInputValue?.length < validLength">{{context.okText}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
