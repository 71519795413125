<div [innerHtml]="jsonLDString"></div>
<h1 class="hidden">Free Cell Phone Service Every Month</h1>
<app-banners></app-banners>
<section class="featured-plans-section">
  <app-featured-plans></app-featured-plans>
</section>
<section class="why-us">
  <div class="page-section section-container">
    <div class="image-section">
      <picture>
        <source width="250" srcset='assets/img/why-us-mobile.svg' media="(max-width: 639.9px)" type="image/svg">
        <source width="400" height="316" srcset='assets/img/why-us-tablet.svg' media="(max-width: 1024.9px)"
          type="image/svg">
        <source width="429" height="400" srcset='assets/img/why-us.svg' media="(min-width: 1025px)" type="image/svg">
        <img width="429" height="400" src="assets/img/why-us.svg" alt="Why Good Mobile">
      </picture>
    </div>
    <div class="details-section">
      <p class="note label">WHY US</p>
      <h2 class="header">Every purchase powers Goodwill mission</h2>
      <p class="details">Your purchase changes lives!</p>
      <p class="smaller description">Good Mobile sales support the Goodwill mission to provide skills training, job
        development, and social services to the community.</p>
    </div>
  </div>
</section>
<section class="bring-device">
  <div class="page-section section-container reverse">
    <div class="details-section">
      <p class="note label">BRING YOUR DEVICE </p>
      <h2 class="header">Bring the device you love </h2>
      <p class="details mb-20">Keep your phone number or get a new one</p>
      <div class="action">
        <button class="button primary" id="Bring-phone" data-cy="Bring-phone"
          [routerLink]="ACTIVATION_ROUTE_URLS.BASE +'/'+ACTIVATION_ROUTE_URLS.CHECK_PHONE" title="Check compatibility"
          value="Bring phone" aria-label="Bring phone">Bring your Device</button>
      </div>
    </div>
    <div class="image-section center">
      <picture class="relative">
        <img src="assets/img/love-your-device.svg" alt="Love Device Image" class="love-device">
        <source height="344" srcset='assets/img/home-bring-device-responsive.png' media="(max-width: 1169.9px)"
          type="image/png">
        <source height="400" srcset='assets/img/home-bring-device.png' media="(min-width: 1025px)" type="image/png">
        <img height="400" src="assets/img/home-bring-device.png" alt="Why Good Mobile">
      </picture>
    </div>
  </div>
</section>
<section class="coverage">
  <div class="page-section section-container">
    <div class="image-section center">
      <picture>
        <source height="400" srcset='assets/img/home-map.svg' type="image/svg">
        <img height="400" src="assets/img/home-map.svg" alt="coverage map">
      </picture>
    </div>
    <div class="details-section">
      <p class="note label">CHECK COVERAGE </p>
      <h2 class="header">Powered by America’s best network</h2>
      <p class="details break">We’re powered by the same network as the largest carriers covering <b>99%</b> of America
      </p>
      <div class="action">
        <button class="button primary" id="Check-Coverage" data-cy="Check-Coverage"
          [routerLink]="SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE" title="Check Coverage"
          value="Check Coverage" aria-label="Check Coverage">Check Coverage</button>
      </div>
    </div>
    
  </div>
</section>