<div class="close-acp-landing-page">
    <section class="banner-section">
        <picture class="banner-image-container">
            <source srcset='assets/img/banners/acp-end-banner-mobile.webp' media="(max-width: 639.9px)"
                type="image/webp">
            <source srcset='assets/img/banners/acp-end-banner-mobile.png' media="(max-width: 639.9px)" type="image/png">
            <source srcset='assets/img/banners/acp-end-banner-tablet.webp' media="(max-width: 1024.9px)"
                type="image/webp">
            <source srcset='assets/img/banners/acp-end-banner-tablet.png' media="(max-width: 1024.9px)"
                type="image/png">
            <source srcset='assets/img/banners/acp-end-banner-desktop.webp' media="(min-width: 1025px)"
                type="image/webp">
            <source srcset='assets/img/banners/acp-end-banner-desktop.png' media="(min-width: 1025px)" type="image/png">
            <img src="assets/img/banners/acp-end-banner-desktop.png" [style.height.px]="calculatedHeight"
                alt="ACP End Banner Image">
        </picture>
        <div class="banner-text-content page-section" #contentWrapper>
            <div class="fcc-box-part">
                <img src="assets/icon/white-fcc-logo.svg" width="24" height="24" alt="FCC Logo">
                <p class="smallest"><b>GOVERNMENT BENEFIT PROGRAM</b></p>
            </div>
            <h1 class="header">April 2024 Announced as the Final fully Funded Month for ACP Benefits</h1>
            <h4 class="sub-header">Due to the lack of additional funding for the ACP, the FCC announces that the last fully funded month for the ACP discount is April 2024.
            </h4>
            <div class="notify-box" *ngIf="!isNotified">
                <a class="link" *ngIf="!showNotifyForm" (click)="openForm()"><img
                        src="assets/icon/primary-envelop-icon.svg" width="24" height="24" alt="Envelop Icon">Notify me
                    when available</a>
                <form *ngIf="!!showNotifyForm" [formGroup]="notifyForm" [ngClass]="{'fade-in': !!showNotifyForm, 'hidden': !showNotifyForm}" >
                    <div class="form-field">
                        <p class="input-title smallest"><b>Give us your email to contact you:</b></p>
                        <div class="input-flex">
                            <label class="outlined-label-input">
                                <input class="input-field" id="email" type="text" name="email" placeholder=" "
                                    formControlName="email" required autocomplete="email" />
                                <span>Enter Email</span>
                            </label>
                            <div class="action">
                                <a (click)="confirmEmail()" id="confirm">Confirm</a>
                            </div>
                        </div>
                        <label class="validation-message"
                            *ngIf="notifyForm.controls.email.hasError('required') && notifyForm.controls.email.touched"
                            id="required-email-msg">
                            Email Address is required
                        </label>
                        <label class="validation-message" *ngIf="notifyForm.controls.email.hasError('pattern')"
                            id="invalid-email-msg">
                            Email Address is invalid. Hint: watch out for extra spaces
                        </label>
                    </div>
                </form>
                <button id="close-popup-btn" *ngIf="showNotifyForm" class="close-button" (click)="hideNotifyForm()"><i class="icon-close"></i></button>
            </div>
            <div class="after-notify-box" *ngIf="!!isNotified">
                <img src="assets/icon/green-check.svg" width="32" height="32" alt="Green Check Icon">
                <p class="smallest">You’ll be notified via email on ACP program updates</p>
            </div>
        </div>
    </section>

    <section class="plan-section page-section">
        <p class="label">SWITCH TO PAID PLAN?</p>
        <h2 class="title">Don't worry! We've got you.</h2>
        <h5 class="sub-title">A special offer on our best-selling plan, get <b class="discount">50%</b> off your first
            month!</h5>
            <div class="categories-container">
                <div class="category" *ngFor="let category of categories" [class.selected]="selectedCategory === category">
                    <p (click)="selectedCategory=category">{{category}}</p>
                </div>
            </div>
        <div class="plans-container">
            <div class="plan-box" *ngFor="let plan of plans" [class.hiddenCard]="plan?.category !== selectedCategory">
                <img src="assets/img/fifty-off-offer.svg" width="139" height="36" alt="Special Offer 50%">
                <div class="box-header">
                    <p class="smaller"><b>{{plan?.category}} PLAN</b></p>
                </div>
                <div class="box-content">
                    <p class="value"><b>{{plan?.details?.data/1024}}GB</b></p>
                    <p class="only-for smallest">only for</p>
                    <p class="price"><b><sup>$</sup>{{plan?.price > 0 ? plan?.price * 0.5 : 'FREE'}}<span
                                class="month">/mo</span><sup class="highlight">*</sup></b></p>
                    <p class="note footer"><sup class="highlight">*</sup>Intro price for first month. Original price <span
                            class="highlight">${{plan?.price > 0 ? plan?.price : 'FREE'}}/mo</span></p>
                    <button class="button primary" (click)="addPlanToCart(plan)">Add to cart</button>
                </div>
            </div>
        </div>
    </section>

    <section class="faqs-section page-section">
        <p class="label">FAQ QUESTIONS</p>
        <h2 class="sec-head">We are here to help you!</h2>
        <h5 class="sub-title">Browse through the most frequently asked questions.</h5>
        <div class="acp-questions">
            <div *ngFor="let qst of firstThreeQuestions" class="questions-container">
                <a class="copy-icon-link"
                    (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId, true)"><img
                        src="/assets/icon/copy-icon.svg" class="copy-icon" alt="copy icon"><span
                        class="tool-tip-text">Copy question
                        url</span> <span *ngIf="!!isCopied && qst?.fields?.questionId == questionIdParam"
                        class="copied">Copied!</span></a>
                <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                    [isActive]="qst?.fields?.questionId == questionIdParam"
                    (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                    <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                </app-question-answer>
            </div>
            <div *ngIf="!!showMore">
                <div *ngFor="let qst of restQuestions">
                    <a class="copy-icon-link"
                        (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId, true)"><img
                            src="/assets/icon/copy-icon.svg" class="copy-icon" alt="copy icon"><span
                            class="tool-tip-text">Copy question
                            url</span> <span *ngIf="!!isCopied && qst?.fields?.questionId == questionIdParam"
                            class="copied">Copied!</span></a>
                    <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                        [isActive]="qst?.fields?.questionId == questionIdParam"
                        (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                        <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                    </app-question-answer>
                </div>
            </div>
        </div>
        <a class="faqs-action" (click)="showMore = !showMore"
            *ngIf="!!restQuestions && restQuestions?.length > 0">{{!showMore ? 'More' : 'Less'}}
            FAQs<img src="/assets/icon/next-blue-arrow.svg" width="24" height="24" alt="Arrow Icon"></a>
    </section>
</div>