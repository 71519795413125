/**
 *
 * Add the app configurations as constants here and export them to be used on
 * the app module.
 *
 */
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}$';
export const NAME_PATTERN = '^[A-Za-z]+[ \t]?[A-Za-z- ]+?[ \t]*$';
export const POSTAL_PATTERN = '^\d{5}(-\d{4})?$';
export const NUMBERS_ONLY_PATTERN = '^[0-9]*$';
export const EBB_NAME_PATTERN = '^[a-zA-Z-\'\` ]*$';
export const ALPHANUMERIC_PATTERN = '^[a-zA-Z0-9_ ]*$';
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
export const NETWORKS = {
    ATT: 'att',
    TMO: 'tmo'
};
export const ERROR_MESSAGES = {
    ADDRESS: 'The address you selected is invalid. Please try another one.',
    IMEI: 'The device serial number does not seem to be valid. Please try another one.',
    NETWORK_MISMATCH: 'Changing a device might impact your service, please contact customer care to ensure that your changes are handled correct.',
    NO_COVERAGE: 'The address you entered doesn\'t have sufficient coverage on our network at this time. Please try another address.',
    NO_COVERAGE_EQUIPMENT: 'The device you checked is not supported on our network at this time. Please try another device.',
    NETWORK_MISMATCH_ACTIVATION: 'The selected address doesn\'t seem to be compatible with your SIM card. Please try another address.',
};

export const SKIP_DEVICE_ATT = { network: 'att', networkType: 'GSM', skuIdentifier: 'G', skuNumber: 'SIMGWL4GLTE', verified: true };
export const SKIP_DEVICE_TMO = { network: 'tmo', networkType: 'GSM', skuIdentifier: 'T', skuNumber: 'SIMGWLTMO4GLTE', verified: true };
export const ENABLED_ATT_SKIP = true;
export const CAPTCHA_BOT_ERROR_CODE = 466;
// export const SKIP_DEVICE_ATT_ESIM = { network: 'att', networkType: 'GSM', skuIdentifier: 'GE', skuNumber: 'ESIMG2G4GLTE', verified: true }; // NOT SUPPORTED
// export const SKIP_DEVICE_TMO_ESIM = { network: 'tmo', networkType: 'GSM', skuIdentifier: 'TE', skuNumber: 'ESIMGWLTMO4GLTE', verified: true }; // NOT SUPPORTED
export const BRAND = 'Good Mobile';