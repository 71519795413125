import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ContentfulService } from 'src/services/contentful.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CAPTCHA_BOT_ERROR_CODE, EMAIL_PATTERN } from '../app.config';
import { ActionsAnalyticsService, CART_TYPES, CustomizableMobilePlan, MobileCustomPlansService, MobilePlanItem, PURCHASE_INTENT } from '@ztarmobile/zwp-service-backend';
import { Subscription, take, takeWhile } from 'rxjs';
import { combineLatest} from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SHOP_ROUTE_URLS } from '../app.routes.names';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ModalHelperService } from 'src/services/modal-helper.service';
import { AppState } from '../app.service';
import { NewsletterService } from '@ztarmobile/zwp-service-backend-v2';

@Component({
  selector: 'app-close-landing-ebb',
  templateUrl: './close-landing-ebb.component.html',
  styleUrls: ['./close-landing-ebb.component.scss']
})
export class CloseLandingEbbComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  public currentPlan: CustomizableMobilePlan = new CustomizableMobilePlan();
  public notifyForm: FormGroup;
  public showNotifyForm = false;
  public isNotified = false;
  public plans: Array<MobilePlanItem>;
  public selectedPlan: MobilePlanItem = {} as MobilePlanItem;
  public firstThreeQuestions;
  public restQuestions;
  public showMore = false;
  public questionIdParam: any;
  public collapsed: boolean;
  public isCopied = false;
  public categories = [];
  public captchaResponse: string;
  public isLoggedIn = false;
  public userEmail = '';
  public action = 'gm_acp_newsletter_subscription';
  public recaptchaResolvedSubscription: Subscription;

  calculatedHeight;
  private allBasePlans: Array<MobilePlanItem>;
  private alive = true;
  selectedCategory: any;
  isReplacePlan: any;

  constructor(
    private router: Router,
    private contentfulService: ContentfulService,
    private clipboardService: ClipboardService,
    private location: Location,
    private formBuilder: FormBuilder,
    private mobilePlansService: MobileCustomPlansService,
    private simpleAuthService: SimpleAuthService,
    private newsLettersService: NewsletterService,
    private angularFireService: AngularFireAuth,
    private modalHelper: ModalHelperService,
    private analyticsService: ActionsAnalyticsService,
    private appState: AppState,
    private route: ActivatedRoute) {

    this.notifyForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])]
    });
    this.mobilePlansService.currentPlan.pipe(takeWhile(() => this.alive)).subscribe((plan) => {
      this.currentPlan = plan;
    });
    this.mobilePlansService.isConfigurationReady.pipe(combineLatest(this.route.params, (isReady, params: Params) => {
      if (isReady) {
        this.allBasePlans = this.mobilePlansService.allBasePlans;
        this.plans = this.mobilePlansService.allBasePlans.filter(plan => !!plan.parentId &&
          !!plan?.isSpecialPromotion && plan?.specialPromotion.utm_source === 'acp');
        this.plans.map((p) => this.categories.push(p.category));
        this.selectedCategory = this.categories[0];
      }
      if (!!params[SHOP_ROUTE_URLS.PARAMS.REPLACE_PLAN]) {
        this.isReplacePlan = params[SHOP_ROUTE_URLS.PARAMS.REPLACE_PLAN];
      }
    })).subscribe();

    this.simpleAuthService.userState.pipe(takeWhile(() => this.alive))
      .subscribe((authState) => {
        if (!!authState) {
          this.isLoggedIn = !!authState && !authState.isAnonymous;
          this.userEmail = authState.email;
        }
      });

  }


  ngOnInit(): void {
    this.contentfulService.getQuestionsByCategoryId('good2goFaqs', 'close-acp').subscribe(questions => {
      if (!!questions) {
        const allQuestions = questions[0].fields.questions;
        this.firstThreeQuestions = allQuestions?.slice(0, 3);
        this.restQuestions = allQuestions?.slice(3);
      }
    });
  }

  ngAfterViewInit() {
    this.calculateHeight();
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  public calculateHeight() {
    setTimeout(() => {
      const contentHeight = this.contentWrapper.nativeElement.offsetHeight;
      this.calculatedHeight = contentHeight;
    });
  }

  public openForm() {
    if (this.isLoggedIn) {
      this.subscribeToNotifications();
    } else {
      this.showNotifyForm = true;
      this.calculateHeight();
    }
  }

  public confirmEmail(): void {
    this.notifyForm.markAllAsTouched();
    if (!!this.notifyForm.valid) {
      this.appState.loading = true;
      this.appState.resetAndExecuteCaptchaSubject.next({action: this.action});
      this.subscribeToNotifications();
    }
    this.calculateHeight();
  }
  private subscribeToNotifications() {
    this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
    this.captchaResponse = response;
    const enteredEmail = this.notifyForm.controls.email.value;
      if ((!!enteredEmail || !!this.userEmail) && !!this.captchaResponse) {
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.callNewsLetterService(enteredEmail, this.captchaResponse, true, this.action);
      }
    });
  }
  private callNewsLetterService(enteredEmail, captchaRes, invisibleRecaptcha, action?): void {
    this.appState.loading = true;
    this.newsLettersService.subscribe({ email: (this.isLoggedIn) ? this.userEmail : enteredEmail }, 'acp-newsletter', captchaRes, !!action? action : null, invisibleRecaptcha).then((res) => {
      this.appState.loading = false;
      this.isNotified = true;
      this.showNotifyForm = false;
    }, (error) => {
      this.appState.loading = false;
      if(error?.error?.errors[0]?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result) => {
          if (!!result) {
            this.callNewsLetterService(enteredEmail, result, false);
          }
        });
       } else {
        this.isNotified = false;
       }
      this.recaptchaResolvedSubscription?.unsubscribe();
    });
  }
  public addPlanToCart(selectedPlan): void {
    if (!!this.isReplacePlan) {
      this.addPlan(this.selectedPlan);
      this.mobilePlansService.setBasePlan(selectedPlan);
      this.mobilePlansService.setCartType(CART_TYPES.NEW_PLAN);
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
    } else {
      if (!!this.currentPlan && !!this.currentPlan.planDevice && this.currentPlan.planDevice.id) {
        this.mobilePlansService.removePhonesFromCart();
        this.mobilePlansService.setPlanExpectedDevice(null);
        this.mobilePlansService.setBasePlan(selectedPlan);
        this.mobilePlansService.setCartType(CART_TYPES.NEW_PLAN);
        this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
      } else {
        this.checkUser(selectedPlan);
      }
    }
  }

  public toggleActive(questionId, answerId, copy?): void {
    if (this.questionIdParam === questionId && !this.collapsed && !copy) {
      this.questionIdParam = 'q0';
    } else {
      this.questionIdParam = questionId;
      this.collapsed = false;
      this.callRichText(answerId);
    }
    if (!!copy && this.questionIdParam === questionId) {
      const url = window.location.host + this.location.path();
      this.clipboardService.copy(url);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1500);
    }
  }

  public hideNotifyForm() {
    if(!!this.notifyForm) {
      this.notifyForm.reset();
    }
    this.showNotifyForm = false;
    this.calculateHeight();
  }

  private callRichText(answerId): void {
    this.contentfulService.getRichText('questions', answerId);
  }

  private checkUser(plan: MobilePlanItem): void {
    this.simpleAuthService.userState.pipe(take(1)).subscribe((authState) => {
      const isLoggedIn = !!authState && !authState.isAnonymous;

      if (!isLoggedIn) {
        this.angularFireService.signInAnonymously().then((user) => {
          setTimeout(() => {
            this.checkCart(plan);
          }, 1000);
        });
      } else {
        this.checkCart(plan);
      }
    });
  }

  private clearCart(): void {
    const removedItems = [];
    if (this.currentPlan.cartType !== CART_TYPES.PLAN_ITEMS) {
      removedItems.push(this.currentPlan.basePlan);
    } else {
      if (this.currentPlan.simsQuantity > 0) {
        removedItems.push({ id: 'SIMGWLTMO4GLTE', quantity: this.currentPlan.simsQuantity, price: 5, type: 'plan-item', title: 'SIM CARD' });
      }
      if (!!this.currentPlan.addOns) {
        removedItems.push(this.currentPlan.addOns);
      }
    }
    this.analyticsService.trackRermoveFromCartGA4(removedItems);
    this.mobilePlansService.setSimPurchaseQuantity(0);
    this.mobilePlansService.setAddonsList(null, this.currentPlan);
    this.mobilePlansService.setActivePlanId('');
    this.appState.clearSessionStorage();
    this.mobilePlansService.clearUserCart();
  }

  private checkCart(plan: MobilePlanItem): boolean {
    // eslint-disable-next-line eqeqeq
    if (!!this.currentPlan && !!this.currentPlan.cartType && this.currentPlan.cartType != CART_TYPES.NEW_PLAN) {
      this.modalHelper.showConfirmMessageModal('Clear Cart', 'Adding new plan will remove other items in your cart. Do you want to proceed?', 'Yes', 'No', 'clean-cart-modal')
        .afterClosed().subscribe((result) => {
          if (!!result) {
            this.clearCart();
            setTimeout(() => {
              this.addPlan(plan);
              sessionStorage.removeItem('planFlow');
              this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
            }, 500);
            return true;
          }
        }, (error) => {
          console.error('error', error);
          return false;
        });
    } else {
      this.addPlan(plan);
      this.mobilePlansService.setActivePlanId('');
      sessionStorage.removeItem('planFlow');
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
      return true;
    }
  }

  private addPlan(plan): void {
    sessionStorage.setItem('planID', JSON.stringify(plan.id));
    this.analyticsService.trackAddToCartGA4(PURCHASE_INTENT.NEW, [plan], plan.price);
    this.mobilePlansService.setSimCard('');
    this.mobilePlansService.setAutoRenewPlan(true);
    sessionStorage.removeItem('useFromBalance');
    sessionStorage.removeItem('useFromReward');
    sessionStorage.removeItem('removeFromCart');
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.calculateHeight();
  }
}
