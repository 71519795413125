<section class="banner-section">
    <picture class="banner-image-container">
        <source srcset='assets/img/banners/hd-voice-mobile.webp' media="(max-width: 639.9px)" type="image/webp">
        <source srcset='assets/img/banners/hd-voice-mobile.png' media="(max-width: 639.9px)" type="image/png">
        <source srcset='assets/img/banners/hd-voice-tablet.webp' media="(max-width: 1024.9px)" type="image/webp">
        <source srcset='assets/img/banners/hd-voice-tablet.png' media="(max-width: 1024.9px)" type="image/png">
        <source srcset='assets/img/banners/hd-voice-desktop.webp' media="(min-width: 1025px)" type="image/webp">
        <source srcset='assets/img/banners/hd-voice-desktop.png' media="(min-width: 1025px)" type="image/png">
        <img src="assets/img/banners/hd-voice-desktop.png" alt="HD Voice">
    </picture>
    <div class="page-section banner-text-content">
      <h1 class="header">HD Voice</h1>
      <p class="sub-header">A new dimension in voice quality for Good Mobile customers</p>
    </div>
</section>
<section class="steps-container">
    <div class="steps-section">
        <div class="ltr-step page-section">
            <div class="description">
                <h2 class="header-color">Get clear audio on calls</h2>
                <p class="smaller">HD Voice is a wideband audio technology that helps make calls sound much crisper and allows you
                    to use multiple features on your phone at the same time. By extending the frequency range of audio signals, HD Voice does two things well.</p>
            </div>
            <div class="step-image">
                <picture class="banner-image-container">
                    <source srcset='assets/img/hd-voice-1.svg' type="image/svg">
                    <source srcset='assets/img/hd-voice-1.webp' type="image/webp">
                    <img src="assets/img/hd-voice-1.svg"  alt="Get clear audio on calls">
                </picture>
            </div>
        </div>
        <div class="rtl-step dark-blue">
            <div class="page-section">
                <div class="step-image">
                    <picture class="banner-image-container">
                        <source srcset='assets/img/hd-voice-2.svg' type="image/svg">
                        <source srcset='assets/img/hd-voice-2.webp' type="image/webp">
                        <img src="assets/img/hd-voice-2.svg"  alt="Multitask with VoLTE">
                    </picture>
                </div>
                <div class="description">
                    <h2 class="header-color">Multitask with VoLTE</h2>
                    <p class="smaller">HD Voice allows you to do it all once on your phone: Talk, browse, post, message, use apps and stream at 4G LTE speeds.</p>
                </div>
            </div>
        </div>
        <div class="ltr-step page-section light-blue">
            <div class="description">
                <h2 class="header-color">How does HD Voice work?</h2>
                <p class="smaller">HD Voice, which is powered by VoLTE (voice over LTE), uses wide-band audio technology and noise cancellation. That means voices sound more natural, and you’ll hear much less background noise compared to standard voice calls</p>
            </div>
            <div class="step-image">
                <picture class="banner-image-container">
                    <source srcset='assets/img/hd-voice-3.svg' type="image/svg">
                    <source srcset='assets/img/hd-voice-3.webp' type="image/webp">
                    <img src="assets/img/hd-voice-3.svg"  alt="How does HD Voice work?">
                </picture>
            </div>
        </div>
    </div>
</section>
<section class="faqs-section page-section">
    <div class="title-section">
        <p class="label">FAQ QUESTIONS</p>
        <h2 class="header-color">We are here to help you!</h2>
        <h5>Browse through the most frequently asked questions.</h5>
    </div>
    <div class="hd-voice-questions">
        <div class="row">
            <div class="twelve questions">
                <div *ngFor="let qst of firstThreeQuestions">
                    <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                        [isActive]="qst?.fields?.questionId == questionIdParam"
                        (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                        <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                    </app-question-answer>
                </div>
                <div *ngIf="!!showMore">
                    <div *ngFor="let qst of restQuestions">
                        <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                            [isActive]="qst?.fields?.questionId == questionIdParam"
                            (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                            <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                        </app-question-answer>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="more-or-less-faqs">
        <button class="button thirdly no-padding" (click)="showMore = true" *ngIf="!showMore">More FAQs</button>
        <button class="button thirdly no-padding" (click)="showMore = false; questionId = 'q0'" *ngIf="!!showMore">Less FAQs</button>
    </p>
</section>
<section class="page-section hd-voice-info">
    <p class="caption">Most unlocked GSM phones will work on the Good Mobile network, however, we don’t have control over the availability of features on your unlocked phone. Unfortunately, some features like Wi-Fi Calling, HD Voice and VoLTE as well as MMS may not work on our network. Good Mobile cannot and does not guarantee the functionality and performance of devices used on the Good Mobile network. Nor do we guarantee the functionality of any devices. As a courtesy, we provide Internet and picture messaging (MMS) settings for some devices. Good Mobile cannot guarantee the effectiveness of these settings, nor their placement in the device, and therefore does not assume any liability for the lack of effectiveness of these devices.</p>
    <p class="caption">Wi-Fi Calling requires a Wi-Fi Calling capable smartphone and access to a Wi-Fi network. Once enable, your phone automatically makes/receives calls/text messages over WiFi if in the U.S. If wireless network coverage is weak or unavailable, or outside the Domestic Coverage Area anytime you connect to a Wi-Fi network. Wi-Fi Calling is available on select devices. Use of Wi-Fi Calling may be restricted in some countries. If you move in or out of Wi-Fi coverage while on a Wi-Fi call, your call will disconnect unless you have HD Voice coverage. (911 calls will disconnect even if you are within HD Voice coverage). HD Voice is not available everywhere. To enable Wi-Fi Calling, you must have a wireless account provisioned for HD Voice. You cannot use Wi-Fi Calling to call 211, 311, 511, and 811. Learn more.</p>
</section>