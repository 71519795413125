<form [formGroup]="addressFieldsForm">
  <div class="row form-section">
    <fieldset class="address-lookup twelve" *ngIf="showAlias">
      <label class="outlined-label-input" for="addressAlias">
        <input id="addressAlias" data-cy="addressName" type="text" formControlName="alias" (change)="addressFieldChanged($event)"
        maxlength="50" placeholder=" ">
        <span>Name</span>
    </label>
      <div class='form-text error'>
        <label class="validation-message" data-cy="addressNameRequiredMsg" *ngIf="addressFieldsForm.controls.alias.touched && addressFieldsForm.controls.alias.hasError('required')">Name is a required field</label>
        <label class="validation-message" data-cy="addressNameInvalidMsg" *ngIf="addressFieldsForm.controls.alias.value && addressFieldsForm.controls.alias.hasError('pattern')">Name is invalid</label>
      </div>
    </fieldset>
    <fieldset class="address-lookup eight">
      <label class="outlined-label-input">
        <input formControlName="address1" matInput type="search" list-formatter="description"  
        id="addressLookup" data-cy="addressLookup" #addressLookup type="search"
          class="geolocate"  [matAutocomplete]="auto" required placeholder=" "
          (input)="changedAddress()" (change)="validateAddress1(addressFieldsForm.controls.address1.value)" [readonly]="readonly"/>
   
          <span class="addressName">{{!billingAddressAlias ? 'Address' : 'Billing Address'}}</span>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option?.description }}
            </mat-option>
          </mat-autocomplete>
    </label>
      <div class='form-text error'>
        <label id ="billingAddress-msg" data-cy="addressIsRequiredMSG" class="validation-message" data-cy="addressRequiredMsg" *ngIf="addressFieldsForm.controls.address1.touched && addressFieldsForm.controls.address1.hasError('required')">Address is a Required Field</label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label class="outlined-label-input">
        <input id="address2" data-cy="suiteNo" type="text" formControlName="address2" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="address-line2" placeholder=" ">
        <span>Suite/Apt. No.</span>
    </label>
    </fieldset>
  </div>
  <div class="row payment-form address-lookup-details" [class.active]="hasDetails" [class.hidden]="!hasDetails">
    <fieldset class="four">
      <label class="outlined-label-input">
        <input id="billingCity"  data-cy="billingCity" type="text" formControlName="city" [readonly]="readonly" placeholder=" "
        (change)="addressFieldChanged($event)" autocomplete="address-level2">
        <span>City</span>
    </label>
      <div class='form-text error'>
        <label id="city-msg" data-cy="cityIsRequired" class="validation-message" *ngIf="addressFieldsForm.controls.city.touched && addressFieldsForm.controls.city.hasError('required')">City is a Required Field</label>
      </div>
      <div class='form-text error'
        *ngIf="addressFieldsForm.controls.city.dirty && addressFieldsForm.controls.city.hasError('pattern')">
        <label id="city-invalid-msg" data-cy="invalidCityMsg" class="validation-message">Invalid City </label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label class="outlined-label-input">
        <input id="billingState" data-cy="billingState" type="text" formControlName="state" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="address-level1" maxlength="2" minlength="2" placeholder=" ">
        <span>State</span>
      </label>
      <div class='form-text error'
        *ngIf="addressFieldsForm.controls.state.touched && addressFieldsForm.controls.state.hasError('required')">
        <label id="state-required-msg" data-cy="requiredStateMsg" class="validation-message">State is a required field </label>
      </div>
      <div class='form-text error'
        *ngIf="addressFieldsForm.controls.state.dirty && !!addressFieldsForm.controls.state.value && !addressFieldsForm.controls.state.valid">
        <label id="state-invalid-msg" data-cy="invalidStateMsg" class="validation-message">Invalid State </label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label class="outlined-label-input">
        <input id="billingPostal"  data-cy="billingPostal" type="text" formControlName="postalCode" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="postal-code" maxlength="10" minlength="5" placeholder=" ">
        <span>Postal Code </span>
      </label>
      <div class='form-text error'>
        <label id="postalCode-msg" class="validation-message" data-cy="postalIsRequired" *ngIf="addressFieldsForm.controls.postalCode.touched && addressFieldsForm.controls.postalCode.hasError('required')">Postal Code is a Required Field</label>
      </div>
      <div class='form-text error'
        *ngIf="addressFieldsForm.controls.postalCode.dirty && addressFieldsForm.controls.postalCode.hasError('pattern')">
        <label id="postalCode-invalid-msg" data-cy="invalidPostalCodeMsg" class="validation-message">Invalid Postal Code </label>
      </div>
    </fieldset>
  </div>
  <input type="hidden" formControlName="country" autocomplete="country">
</form>