import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CAPTCHA_SITE_ID } from '@env/environment';
import { ModalHelperService } from '@services/modal-helper.service';
import { ReCaptchaComponent } from '@widgets/re-captcha/re-captcha.component';

@Component({
  selector: 'app-recaptcha-verification',
  templateUrl: './recaptcha-verification.component.html',
  styleUrl: './recaptcha-verification.component.scss'
})
export class RecaptchaVerificationComponent implements OnInit {
  @ViewChild('reCaptcha') reCaptcha: ReCaptchaComponent;

  public SITE_ID: string = CAPTCHA_SITE_ID;
  public captchaResponse: string;
  public verifyClicked = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<any>,
    private modalHelper: ModalHelperService) {

  }
  ngOnInit(): void {
    this.hideInvisibleRecaptcha();
  }
  public hideInvisibleRecaptcha(): void {
    document.getElementById('reCaptchaContainer').style.display = 'none';
  }
  public showInvisibleRecaptcha(): void {
    document.getElementById('reCaptchaContainer').style.display = 'block';
  }
  public resolvedCaptcha(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }
  public verifyRecaptcha(): void {
    this.verifyClicked = true;
    if (!!this.captchaResponse) {
      this.showInvisibleRecaptcha();
      this.dialog.close(this.captchaResponse);
    }
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (event.keyCode === 27) {
      event.preventDefault();
      this.dialog.close();
      this.modalHelper.showRecaptchaVerificationModal();
    }
  }
}
